export const contactForm = {
  ADDRESS: "العنوان",
  AGE: "العمر",
  BIRTHDAY: "تاريخ الميلاد",
  CHANGE_PASSWORD: "تغيير كلمة المرور",
  CITY: "المدينة",
  CONFIRM_PASSWORD: "اعادة كلمة المرور",
  CREATE_ACCOUNT: "انشاء حساب",
  DAY: "اليوم",
  EMAIL: "البريد الإلكتروني",
  ENTER_YOUR_NAME: "الاسم",
  FIRST_NAME: "الإسم الأول",
  FORGET_PASSWORD: "نسيت كلمة المرور؟",
  GET_IN_TOUCH_WITH_US: "تواصل معنا",
  LAST_NAME: "الإسم الأخير",
  LOGIN: "تسجيل دخول",
  MONTH: "الشهر",
  NAME: "الاسم الكامل",
  PASSWORD: "كلمة المرور",
  PHONE: "رقم الهاتف",
  PHONE_MESSAGE: "الرجاء ادخال رقم هاتف صحيح لغايات السحب على المسابقات",
  REFERENCE: "الرمز الترويجي",
  REGISTER: "مستخدم جديد؟",
  RESET_PASSWORD: "ضبط كلمة المرور",
  RESET_PASSWORD_MESSAGE: "تم إرسال رابط إعادة الضبط بنجاح",
  SEND: "إرسال",
  SUBJECT: "العنوان",
  THANK_YOU_MESSAGE:
    "شكراً لك على تواصلك!\n\nنحن نقدر اتصالك بنا. سيتواصل معك أحد أعضاء فريقنا المتخصص في أقرب وقت!",
  USERNAME: "اسم المستخدم",
  YEAR: "السنة",
  YOUR_MESSAGE_HERE: "رسالتك هنا",
};
